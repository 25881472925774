/**
 * List clients.
 */
import { apiDelete, apiGet, apiPut } from '@/util/api';
import Icon from '@/icons/Icon.vue';

export default {
  name: 'Clients',
  components: {
    Icon,
  },
  data() {
    return {
      clients: [],
      clientsLoaded: false,
      loading: true,
      clientsTablePerPage: 10,
      clientsTableCurrentPage: 1,
      clientsTableFields: [
        {
          key: 'name',
          label: this.$t('clients_list_client_name'),
        },
        {
          key: 'isActive',
          label: '',
          class: 'table-user-control',
        },
        {
          key: 'delete',
          label: '',
          class: 'table-user-control',
        },
      ],
      setLock: {
        lock: true,
        client: {},
      },
      deleteClient: {},
    };
  },
  async created() {
    // Get the list of clients from the API.
    try {
      this.clients = await apiGet('/client');
    } catch (error) {
      this.$bvToast.toast(this.$t('clients_error_get_clients'), {
        title: this.$t('common_error_title'),
        variant: 'danger',
      });
    } finally {
      this.loading = false;
      this.clientsLoaded = true;
    }
  },
  methods: {
    /**
     * Show the "set client lock" modal.
     * @param {Number}  clientId
     * @param {Boolean} lock
     */
    confirmSetClientLock(clientId, lock) {
      this.setLock.lock = lock;
      this.setLock.client = this.clients.find(c => {
        return c.id === clientId;
      });
      this.$bvModal.show('client-confirm-set-lock');
    },

    /**
     * Handle the API request which causes a client to be locked or unlocked.
     */
    async doSetClientLock() {
      this.loading = true;
      await apiPut(`/client/${this.setLock.client.id}`, {
        isActive: !this.setLock.lock,
      })
        .then(() => {
          const client = this.clients.find(
            c => c.id === this.setLock.client.id,
          );
          client.isActive = !this.setLock.lock;
          this.$bvToast.toast(
            this.$t(
              this.setLock.lock
                ? 'clients_success_set_client_lock'
                : 'clients_success_set_client_unlock',
              { clientName: this.setLock.client.name },
            ),
            {
              title: this.$t('common_success_title'),
              variant: 'success',
            },
          );
        })
        .catch(() => {
          this.$bvToast.toast(
            this.$t(
              this.setLock.lock
                ? 'clients_error_set_client_lock'
                : 'clients_error_set_client_unlock',
              { clientName: this.setLock.client.name },
            ),
            {
              title: this.$t('common_error_title'),
              variant: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Confirm permanent deletion of a client.
     * @param {Number}  clientId
     */
    confirmDeleteClient(clientId) {
      this.deleteClient = this.clients.find(c => {
        return c.id === clientId;
      });
      this.$bvModal.show('client-confirm-delete');
    },

    /**
     * Handle the API request which deletes the client.
     */
    async doDeleteClient() {
      this.loading = true;
      await apiDelete(`/client/${this.deleteClient.id}`)
        .then(() => {
          // Remove the client from the list
          const clientIndex = this.clients.findIndex(
            c => c.id === this.deleteClient.id,
          );
          this.clients.splice(clientIndex, 1);
          this.$bvToast.toast(
            this.$t('clients_success_delete_client', {
              clientName: this.deleteClient.name,
            }),
            {
              title: this.$t('common_success_title'),
              variant: 'success',
            },
          );
        })
        .catch(() => {
          this.$bvToast.toast(
            this.$t('clients_error_delete_client', {
              clientName: this.deleteClient.name,
            }),
            {
              title: this.$t('common_error_title'),
              variant: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
