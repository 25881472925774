var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-clients"},[_c('b-container',[_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('clients_header')))]),_c('p',[_vm._v(_vm._s(_vm.$t('clients_introduction')))])])],1),_c('b-row',{staticClass:"mt-1 mb-3"},[_c('b-col',{staticClass:"text-right"},[(_vm.loading && _vm.clientsLoaded)?_c('b-spinner',{staticClass:"mr-3",attrs:{"small":""}}):_vm._e(),_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{ name: 'client-new' },"title":_vm.$t('clients_new_client_title')}},[_vm._v(_vm._s(_vm.$t('clients_new_client')))])],1)],1),_c('b-row',[_c('b-col',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.clients,"fields":_vm.clientsTableFields,"per-page":_vm.clientsTablePerPage,"current-page":_vm.clientsTableCurrentPage,"show-empty":true,"empty-text":_vm.$t('clients_no_clients')},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{attrs:{"to":{
                name: 'client-edit',
                params: { clientId: data.item.id },
              }}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(isActive)",fn:function(data){return [_c('Icon',{staticStyle:{"height":"1.5em","cursor":"pointer"},attrs:{"icon":data.value ? 'interface:unlocked' : 'interface:locked',"stroke":"","fill":"","title":_vm.$t(
                  data.value
                    ? 'clients_title_client_unlocked'
                    : 'clients_title_client_locked'
                ),"emit":"clicked"},on:{"clicked":function($event){return _vm.confirmSetClientLock(data.item.id, data.value)}}})]}},{key:"cell(delete)",fn:function(data){return [_c('Icon',{staticStyle:{"height":"1.5em","cursor":"pointer"},attrs:{"icon":"interface:delete","stroke":"","fill":"","title":_vm.$t('clients_title_client_delete'),"emit":"clicked"},on:{"clicked":function($event){return _vm.confirmDeleteClient(data.item.id)}}})]}},{key:"empty",fn:function(scope){return [_c('div',{staticClass:"text-center"},[(_vm.loading)?_c('span',[_c('b-spinner')],1):_c('span',[_vm._v(_vm._s(scope.emptyText))])])]}}])}),(_vm.clients.length > _vm.clientsTablePerPage)?_c('b-pagination',{attrs:{"total-rows":_vm.clients.length,"per-page":_vm.clientsTablePerPage,"align":"center"},model:{value:(_vm.clientsTableCurrentPage),callback:function ($$v) {_vm.clientsTableCurrentPage=$$v},expression:"clientsTableCurrentPage"}}):_vm._e()],1)],1)],1),_c('b-modal',{attrs:{"id":"client-confirm-set-lock","title":_vm.$t(
        _vm.setLock.lock
          ? 'clients_modal_confirm_lock_title'
          : 'clients_modal_confirm_unlock_title',
        {
          clientName: _vm.setLock.client.name,
        }
      )},on:{"ok":_vm.doSetClientLock}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( _vm.setLock.lock ? 'clients_modal_confirm_lock_text' : 'clients_modal_confirm_unlock_text', { clientName: _vm.setLock.client.name, } ))+" ")])]),_c('b-modal',{attrs:{"id":"client-confirm-delete","title":_vm.$t('clients_modal_confirm_delete_title', {
        clientName: _vm.deleteClient.name,
      }),"ok-variant":"danger","ok-title":_vm.$t('common_delete')},on:{"ok":_vm.doDeleteClient}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('clients_modal_confirm_delete_text', { clientName: _vm.deleteClient.name, }))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }